/**
 *
 * @param {string} text
 */

function renderHTML(text) {
  if (typeof text !== "string") {
    throw new Error("The parameter is not a string");
  }

  return { __html: text };
}

export default renderHTML;
