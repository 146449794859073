import React from "react";
import renderHTML from "../../../helpers/renderHTML";

const Service = serviceData => {
  return (
    <a href={serviceData.serviceData.path} className="service-single">
      <div className="single-shadow" />
      <div className="service-single__content">
        <div className="left-border" />
        <div className="right-border" />
        <div className="bottom-border" />
        <div className="service-single__wrapper">
          <div className="service-single__icon">
            <img
              className="service-icon responsive-img lazyload"
              alt={`${serviceData.serviceData.title} | pandagroup.co`}
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mMsOaZdDwAFAQHms1Zh0AAAAABJRU5ErkJggg=="
              data-src={
                serviceData.serviceData.acf.service_icon.localFile.childImageSharp.original.src
              }
            />
          </div>
          <div className="service-single__text">
            <h5
              className="service-single__title"
              dangerouslySetInnerHTML={renderHTML(serviceData.serviceData.title)}
            />
            <div className="service-single__description">
              <p>{serviceData.serviceData.acf.service_short_info}</p>
            </div>
          </div>
        </div>
        <div className="service-single__button">
          <a href={serviceData.serviceData.path}>
            <div className="btn__cta btn__cta--green btn__cta--inverse">
              <span>read more</span> <span className="btn__cta--arrow btn__cta--inverse" />
            </div>
          </a>
        </div>
      </div>
    </a>
  );
};

export default Service;
