import React from "react";
import Service from "./components/Service";

const buildServices = options => {
  const listServices = options.map(option => (
    <Service serviceData={option.node} key={option.node.wordpress_id} />
  ));
  return <div className="services-grid">{listServices}</div>;
};

const Services = servicesData => {
  return (
    <>
      <section className="services">
        <div className="container">{buildServices(servicesData.servicesData.edges)}</div>
      </section>
    </>
  );
};

export default Services;
