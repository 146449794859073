import React, { PureComponent } from "react";
import { graphql } from "gatsby";

import Helmet from "react-helmet";
import Layout from "../Layout/Layout";
import Services from "../modules/Services/Services";
import SEO from "../Layout/Seo";

class PageServicesTemplate extends PureComponent {
  render() {
    const currentPage = this.props.data.wordpressPage;
    const services = this.props.data.allWordpressWpServices;
    const metaTitle = currentPage.yoast.title || "";
    const metaDesc = currentPage.yoast.metadesc || "";
    const metaKeyWords = currentPage.yoast.metakeywords || "";

    return (
      <Layout>
        <Helmet
          bodyAttributes={{
            class: "servicespage"
          }}
        />
        <SEO title={metaTitle} keywords={metaKeyWords} description={metaDesc} />
        <div className="services-wrapper">
          <div className="section__header">
            <h2 className="section__title--large">{currentPage.title}</h2>
          </div>
          <Services servicesData={services} />
        </div>
      </Layout>
    );
  }
}

export default PageServicesTemplate;

export const pageQuery = graphql`
  query currentServicePageMainQuery($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      slug
      id
      link
      yoast {
        title
        metakeywords
        metadesc
      }
      date(formatString: "MMMM DD, YYYY")
    }
    allWordpressWpServices(sort: { fields: [date], order: ASC }) {
      edges {
        node {
          title
          wordpress_id
          path
          acf {
            service_short_info
            service_icon {
              source_url
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
